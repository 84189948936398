
import { IonApp, IonRouterOutlet, IonButton } from "@ionic/vue";
import { defineComponent, ref } from "vue";

import { Drivers, Storage } from "@ionic/storage";

//import { useRouter } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonButton,
  },
  setup() {
    const deferredPrompt = ref(null);

    const storage = new Storage({
      name: "__mydb",
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    });

    async function createStore() {
      await storage.create();
    }

    createStore();

    /* Receive event before install pwa to prompt user */
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      //console.log(e);
      deferredPrompt.value = e;
    });

    window.addEventListener("appinstalled", () => {
      deferredPrompt.value = null;
    });

    async function dismiss() {
      deferredPrompt.value = null;
    }
    async function install() {
      deferredPrompt.value.prompt();
    }

    return {
      deferredPrompt,
      dismiss,
      install,
    };
  },
});
