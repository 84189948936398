import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Tabs from "../views/Tabs.vue";
import Login from "../views/Login.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        component: Login,
        beforeEnter: (to, from, next) => {
            const user = localStorage.getItem("userInfo");
            const ricordami = localStorage.getItem("ricordami");
            if (user && ricordami) {
                next({ path: "/tabs/" });
            } else {
                next();
            }
        },
    },
    {
        path: "/tabs/",
        component: Tabs,
        meta: { requiresAuth: true },
        children: [
            {
                path: "",
                redirect: "/tabs/tab1",
            },
            {
                //Clienti
                path: "tab1",
                component: () => import("@/views/Tab1.vue"),
            },
            {
                //Calendario appuntamenti
                path: "tab2",
                component: () => import("@/views/Tab2.vue"),
            },
            {
                //Ricerca mappa
                path: "tab4",
                component: () => import("@/views/Tab4.vue"),
            },
            {
                //Profilo
                path: "tab3",
                component: () => import("@/views/Tab3.vue"),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const user = localStorage.getItem("userInfo");

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (user == null) {
            next({
                path: "/login",
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
