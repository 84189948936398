import axios from "axios";
const TOKEN = "Bearer 64cda7184d52fee957124d505df02576";

export default {
  async handleLogin(email, password) {
    const data = new FormData();
    data.append("users_email", email);
    data.append("users_password", password);
    //data.append("depth", "3");


    const response = await axios.post("/login/users", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response);
    return response;
  },

  /**
  * 
  * @param userID id of the logged user
  * @returns array dipendente for the logged user. If null block login
  * 
  * Get dipendente info for the logged user
  */
  async getUserDipendente(userID) {
    const data = new FormData();
    data.append("where[dipendenti_user_id]", userID.toString());

    const response = await axios.post("/search/dipendenti", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response.data.data[0]);
    return response;
  },
};
