
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from "@ionic/vue";
import { people, calendar, person, pin } from "ionicons/icons";

export default {
    name: "Tabs",
    components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
    setup() {
        return {
            people,
            calendar,
            person,
            pin,
        };
    },
};
