
import {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    //IonItem,
    //IonLabel,
    IonInput,
    IonButton,
    IonCheckbox,
    //IonSpinner,
    IonLoading,
    modalController,
    //toastController,
} from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import axios from "axios";
import md5 from "md5";
import { useRouter } from "vue-router";

import apiLogin from "@/services/login";

import { openToast } from "@/services/toast";
//Set user in indexedDB --> dbUser --> user
import { setUser } from "@/services/localbase";
import PwaInstructionsVue from "@/components/PwaInstructions.vue";

export default defineComponent({
    name: "Login",
    components: {
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        //IonItem,
        //IonLabel,
        IonInput,
        IonButton,
        IonCheckbox,
        //IonSpinner,
        IonLoading,
    },
    setup() {
        const email = ref("");
        const password = ref("");
        const userData: string[] = reactive([]);
        const ricordami = ref(false);
        const loading = ref(false);
        const setOpen = (state: boolean) => (loading.value = state);

        const router = useRouter();

        /*
         * Open pwa instruction page
         */
        async function openModal() {
            const modal = await modalController.create({
                component: PwaInstructionsVue,
            });
            return modal.present();
        }

        /*
         * Handle login
         */
        function doLogin() {
            loading.value = true;
            const hashPassword = md5(password.value.toString());
            const userEmail = email.value;
            const userPassword = hashPassword.toString();

            apiLogin
                .handleLogin(userEmail, userPassword)
                .then((response) => {
                    if (response.data.status == 0 && response.data.data.length != 0) {
                        userData.push(response.data.data);
                        //save user data to localStorage and indexeddb
                        localStorage.setItem("userInfo", JSON.stringify(userData[0]));
                        if (ricordami.value) {
                            localStorage.setItem("ricordami", `${ricordami.value}`);
                        }
                        console.log(response.data.data);
                        setUser(response.data.data);

                        //Clear input and redirect to homepage
                        email.value = "";
                        password.value = "";
                        ricordami.value = false;
                        loading.value = false;
                        router.push("/tabs/");
                    } else {
                        openToast("Login fallito, controlla email e password", "toast_danger");
                    }
                })
                .catch((error) => {
                    openToast("Login fallito, controlla email e password", "toast_danger");
                    console.error(error);
                })
                .finally(() => {
                    loading.value = false;
                });
        }

        /*     function doLogin() {
      loading.value = true;
      const hashPassword = md5(password.value.toString());
      const userEmail = email.value;
      const userPassword = hashPassword.toString();

      apiLogin
        .handleLogin(userEmail, userPassword)
        .then((response) => {
          if (response.data.status == 0 && response.data.data.length != 0) {
            const userID = response.data.data["users_id"];
            setUser(response.data.data);

            apiLogin.getUserDipendente(userID).then((response) => {
              //console.log(response);
              // Ho dipendente con questo user_id, salvo e redirect alla home
              if (
                response.data.status === 0 &&
                response.data.data.length != 0
              ) {
                userData.push(response.data.data[0]);
                //save user data to localStorage and indexeddb
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(response.data.data[0])
                );
                if (ricordami.value) {
                  localStorage.setItem("ricordami", `${ricordami.value}`);
                }

                //Clear input and redirect to homepage
                email.value = "";
                password.value = "";
                ricordami.value = false;
                loading.value = false;

                router.push("/tabs/");
              } else {
                openToast(
                  "Login fallito, non hai i permessi per accedere.",
                  "toast_danger"
                );
              }
            });
          } else {
            openToast(
              "Login fallito, controlla email e password",
              "toast_danger"
            );
          }
        })
        .catch((error) => {
          console.error(error);
          openToast(
            "Login fallito, controlla email e password",
            "toast_danger"
          );
        })
        .finally(() => {
          loading.value = false;
        });
    } */

        return {
            email,
            password,
            doLogin,
            ricordami,
            loading,
            setOpen,
            openModal,
        };
    },
});
