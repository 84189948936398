import Localbase from "localbase";

export const dbUser = new Localbase("user");
dbUser.config.debug = false;

export const dbCustomers = new Localbase("clienti");
dbCustomers.config.debug = false;

//Add user to user collection
export async function setUser(user) {
    await dbUser.collection("user").add(user, user.users_id);
    console.log("user added!");
}
//Add all customers to clienti collection
export function setCustomers(customers) {
    dbCustomers.collection("clienti").add(customers, customers.customers_id);
    //console.log("customers added");
}

//Get all customers from clienti collection
export async function getCustomers() {
    try {
        const customers = await dbCustomers.collection("clienti").get();
        //console.log('customers from indexeddDB: ', customers)
        return customers;
    } catch (error) {
        console.log("error: ", error);
    }
}
//Get customer by customers_id
export async function getCustomer(id) {
    try {
        const customer = await dbCustomers
            .collection("clienti")
            .doc({ customers_id: id })
            .get();
        //console.log('customers: ', customer)
        return customer;
    } catch (error) {
        console.log("error: ", error);
    }
}
