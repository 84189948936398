/* eslint-disable no-console */

import { alertController } from '@ionic/vue';
import { register } from 'register-service-worker'


async function newVersion() {
  const alert = await alertController.create({
    header: "Aggiornamento applicazione",
    message: "É disponibile un nuovo aggiornamento dell'applicazione",
    backdropDismiss: false,
    buttons: [
      {
        text: "Installa",
        handler: () => {
          //Call logout function (delete localbasedb and redirect to login page)
          window.location.reload(true);
        },
      },
    ],
  });
  return alert.present();
}

async function appInstaller() {
  const alert = await alertController.create({
    header: "Installa l'applicazione",
    message: "Questa applicazione è fatta per essere installata invece che venire utilizzata dal browser",
    backdropDismiss: false,
    buttons: [
      {
        text: "Installa",
        handler: () => {
          //Call logout function (delete localbasedb and redirect to login page)
          window.location.reload(true);
        },
      },
    ],
  });
  return alert.present();
}




const navigator: any = window.navigator;

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )

      /*       const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches) || (navigator.standalone) || document.referrer.includes('android-app://');
            if (isInStandaloneMode()) {
              console.log("webapp is installed, nothing to do...");
            } else {
              console.log('webapp not installed, force user to install app');
              //appInstaller();
              alert('app non installata')
            } */

    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is available; please refresh.')
      newVersion();
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
